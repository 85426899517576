import { takeEvery, fork, put, all, call, take  } from "redux-saga/effects";

// Login Redux States
import { ChatsActionTypes } from "./types";
import { chatsApiResponseSuccess, chatsApiResponseError } from "./actions";


// import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import { eventChannel, EventChannel } from 'redux-saga';
// import ActionPattern from "redux-saga"
// import { ActionPattern, Buffer, Channel, GuardPredicate, Pattern, Task, Effect } from '@redux-saga/types'
import { ActionPattern } from '@redux-saga/types'
import { Action } from 'redux'

import { getLoggedinUser } from "../../api/apiCore";

import config from "../../config";

import {
  getFavourites as getFavouritesApi,
  getDirectMessages as getDirectMessagesApi,
  getPausedTickets as getPausedTicketsApi,  
  getClosedTickets as getClosedTicketsApi,
  getOpenTickets as getOpenTicketsApi,  
  getChannels as getChannelsApi,
  addContacts as addContactsApi,
  createChannel as createChannelApi,
  getChatUserDetails as getChatUserDetailsApi,
  getChatUserConversations as getChatUserConversationsApi,
  sendMessage,
  receiveMessage as receiveMessageApi,
  readMessage as readMessageApi,
  receiveMessageFromUser as receiveMessageFromUserApi,
  deleteMessage as deleteMessageApi,
  forwardMessage as forwardMessageApi,
  deleteUserMessages as deleteUserMessagesApi,
  getChannelDetails as getChannelDetailsApi,
  toggleFavouriteContact as toggleFavouriteContactApi,
  triggerTicketOperation as triggerTicketOperationApi,
  triggerAddNote as triggerAddNoteApi,  
  triggerTicketTask as triggerTicketTaskApi,  
  getArchiveContact as getArchiveContactApi,
  toggleArchiveContact as toggleArchiveContactApi,
  readConversation as readConversationApi,
  deleteImage as deleteImageApi,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
  showActionNotification, 
  showMessageNotification, 
} from "../../helpers/notifications";

//actions
import {
  getDirectMessages as getDirectMessagesAction,
  getPausedTickets as getPausedTicketsAction,  
  getClosedTickets as getClosedTicketsAction,
  getOpenTickets as getOpenTicketsAction,  
  getFavourites as getFavouritesAction,
  getChannels as getChannelsAction,
  getReceiveUpdate as getReceiveUpdateAction,
} from "./actions";

function* getFavourites() {
  try {
    const response: Promise<any> = yield call(getFavouritesApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_FAVOURITES, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_FAVOURITES, error));
  }
}

function* getDirectMessages() {
  try {
    const response: Promise<any> = yield call(getDirectMessagesApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_DIRECT_MESSAGES, error)
    );
  }
}


function* getPausedTickets() {
  try {
    const response: Promise<any> = yield call(getPausedTicketsApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_PAUSED_TICKETS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_PAUSED_TICKETS, error)
    );
  }
}

function* getClosedTickets() {
  try {
    const response: Promise<any> = yield call(getClosedTicketsApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CLOSED_TICKETS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CLOSED_TICKETS, error)
    );
  }
}

function* getOpenTickets() {
  try {
    const response: Promise<any> = yield call(getOpenTicketsApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_OPEN_TICKETS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_OPEN_TICKETS, error)
    );
  }
}

function* getChannels() {
  try {
    const response: Promise<any> = yield call(getChannelsApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNELS, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_CHANNELS, error));
  }
}

function* addContacts({ payload: contacts }: any) {
  try {
    const response: Promise<any> = yield call(addContactsApi, contacts);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.ADD_CONTACTS, response));
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.ADD_CONTACTS, error));
  }
}
function* createChannel({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(createChannelApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_CHANNEL, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.CREATE_CHANNEL, error));
  }
}

function* getChatUserDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_DETAILS, error)
    );
  }
}

function* getChatUserConversations({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserConversationsApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, error)
    );
  }
}

function* onSendMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendMessage, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_MESSAGE, error));
  }
}

function* receiveMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error));
  }
}

function* receiveMessageFromUser({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageFromUserApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER, error)
    );
  }
}

function* deleteMessage({ payload: { userId, messageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteMessageApi,
      userId,
      messageId
    );
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_MESSAGE, error));
  }
}

function* forwardMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(forwardMessageApi, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.FORWARD_MESSAGE, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(chatsApiResponseError(ChatsActionTypes.FORWARD_MESSAGE, error));
  }
}

function* deleteUserMessages({ payload: userId }: any) {
  try {
    const response: Promise<any> = yield call(deleteUserMessagesApi, userId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_USER_MESSAGES, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.DELETE_USER_MESSAGES, error)
    );
  }
}

function* getChannelDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChannelDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHANNEL_DETAILS, error)
    );
  }
}

function* toggleFavouriteContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleFavouriteContactApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT, error)
    );
  }
}

function* triggerTicketOperation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(triggerTicketOperationApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TRIGGER_TICKET_OPERATION,
        response
      )
    );
    yield call(showActionNotification , response);
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TRIGGER_TICKET_OPERATION, error)
    );
  }
}


function* triggerAddNote({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(triggerAddNoteApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TRIGGER_ADD_NOTE,
        response
      )
    );
    yield call(showActionNotification , response);
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TRIGGER_ADD_NOTE, error)
    );
  }
}

function* triggerTicketTask({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(triggerTicketTaskApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TRIGGER_TICKET_TASK,
        response
      )
    );
    yield call(showActionNotification , response);
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TRIGGER_TICKET_TASK, error)
    );
  }
}



function* getArchiveContact() {
  try {
    const response: Promise<any> = yield call(getArchiveContactApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVE_CONTACT, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_ARCHIVE_CONTACT, error)
    );
  }
}

function* toggleArchiveContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleArchiveContactApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, error)
    );
  }
}

function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
    yield put(getDirectMessagesAction());
    yield put(getPausedTicketsAction());    
    yield put(getClosedTicketsAction());
    yield put(getOpenTicketsAction());
    yield put(getFavouritesAction());
    yield put(getChannelsAction());
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error));
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}


//===================================================

// function initWebsocket() {  
//   return eventChannel(emitter => {    
//     console.log('==logined iser==', getLoggedinUser());
//     const user = getLoggedinUser();
//     if (!user) {
//       return () => {};
//     } 
//         // const newSocket = new WebSocket('ws://localhost:8002/ws/chatapp/?token=0e9b9e0a7794582ac06f43b939e982bc23d2c824');
//     // const wsUrl = `ws://localhost:8002/ws/chatapp/?token=${user.access}`;
//     const wsUrl = `${config.WS_URL}?token=${user.access}`;
//     // console.log('new url',wsUrl1);
//     // console.log(wsUrl);
//     const ws = new WebSocket(wsUrl )  ;  
//     // const token  = 'thistoken';
// //     const ws = new WebSocket(wsUrl, undefined, {
// //     headers: {
// //         Authorization: `Bearer ${token}`
// //     }
// // });

//     ws.onopen = () => {
//       ws.send(JSON.stringify({ type: 'authenticate', token: 'abcdefghij' }));
//       console.log('Opening Websocket ...');

//       // sendPing = () => {
//       //   console.log('Sending Ping ...');
//       // } 

//       function sendPing() {
//           if (ws && ws.readyState === WebSocket.OPEN) {
//               console.log('Sending ping');
//               ws.send(JSON.stringify({ 
//                   'cmd': 'ping'
//               }));
//           }
//       }

//       setInterval(sendPing, 1*60*1000);
//       // JSON.stringify({ 
//       //       'cmd': 'ping',
//       //       'message':message
//       //   })
//       // ws.send(
//       //   JSON.stringify({ 
//       //         'cmd': 'authenticate',
//       //         'message': 'hello',
//       //     })
//       //   );      

//       // ws.send(
//       //   JSON.stringify({ 
//       //         'cmd': 'ping',
//       //         'message': 'hello',
//       //     })
//       //   );
//     }    
//     ws.onerror = (error: any) => {
//       console.log('WebSocket error ' + error);
//       console.dir(error);
//     }    
//     ws.onmessage = (e) => {
//       console.log('Message came back', e);
//       let msg = null;
//       try {
//         msg = JSON.parse(e.data);
//         console.log('==msg==', msg);
//       } catch(e: any) {
//         console.error(`Error parsing : ${e.data}`);
//       }
//       if (msg) {
//         // console.log('===emitting action===');
//         // return emitter(getOpenTicketsAction());
//         // return emitter(getReceiveUpdateAction());
//         // const { payload: book } = msg        
//         const ws_event = msg.event
//         switch (ws_event) {
//           case 'pong':
//             console.log('..pong..');
//             break;
//             // return emitter({ type: ADD_BOOK, book })
//           // case 'REMOVE_BOOK':
//             // return emitter({ type: REMOVE_BOOK, book })
//           default:
//             showMessageNotification('New Message');
//             emitter(getReceiveUpdateAction());
//             break;
//             // nothing to do
//         }
//       }
//     }    
//     // unsubscribe function
//     return () => {
//       console.log('Socket off');
//     }
//   })
// }

// export function* watchWebsockets() {
//   const channel: ActionPattern<Action<any>>  = yield call(initWebsocket);
//   while (true) {
//     const action: Action<any>  = yield take(channel);
//     yield put(action);
//   }
// }

//=====================================================



export function* watchGetFavourites() {
  yield takeEvery(ChatsActionTypes.GET_FAVOURITES, getFavourites);
}

export function* watchGetDirectMessages() {
  yield takeEvery(ChatsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages);
}
export function* watchGetPausedTickets() {
  yield takeEvery(ChatsActionTypes.GET_PAUSED_TICKETS, getPausedTickets);
}
export function* watchGetClosedTickets() {
  yield takeEvery(ChatsActionTypes.GET_CLOSED_TICKETS, getClosedTickets);
}
export function* watchGetOpenTickets() {
  yield takeEvery(ChatsActionTypes.GET_OPEN_TICKETS, getOpenTickets);
}
export function* watchGetChannels() {
  yield takeEvery(ChatsActionTypes.GET_CHANNELS, getChannels);
}
export function* watchAddContacts() {
  yield takeEvery(ChatsActionTypes.ADD_CONTACTS, addContacts);
}
export function* watchCreateChannel() {
  yield takeEvery(ChatsActionTypes.CREATE_CHANNEL, createChannel);
}
export function* watchGetChatUserDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
}
export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchOnSendMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE, onSendMessage);
}
export function* watchReceiveMessage() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, receiveMessage);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}
export function* watchReceiveMessageFromUser() {
  yield takeEvery(
    ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
    receiveMessageFromUser
  );
}
export function* watchDeleteMessage() {
  yield takeEvery(ChatsActionTypes.DELETE_MESSAGE, deleteMessage);
}
export function* watchForwardMessage() {
  yield takeEvery(ChatsActionTypes.FORWARD_MESSAGE, forwardMessage);
}
export function* watchDeleteUserMessages() {
  yield takeEvery(ChatsActionTypes.DELETE_USER_MESSAGES, deleteUserMessages);
}
export function* watchGetChannelDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHANNEL_DETAILS, getChannelDetails);
}
export function* watchToggleFavouriteContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
    toggleFavouriteContact
  );
}
export function* watchTriggerTicketOperation() {
  yield takeEvery(
    ChatsActionTypes.TRIGGER_TICKET_OPERATION,
    triggerTicketOperation
  );
}

export function* watchTriggerAddNote() {
  yield takeEvery(
    ChatsActionTypes.TRIGGER_ADD_NOTE,
    triggerAddNote
  );
}


export function* watchTriggerTicketTask() {
  yield takeEvery(
    ChatsActionTypes.TRIGGER_TICKET_TASK,
    triggerTicketTask
  );
}

export function* watchGetArchiveContact() {
  yield takeEvery(ChatsActionTypes.GET_ARCHIVE_CONTACT, getArchiveContact);
}
export function* watchToggleArchiveContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
    toggleArchiveContact
  );
}
export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}

function* chatsSaga() {
  yield all([
    fork(watchGetFavourites),
    fork(watchGetDirectMessages),
    fork(watchGetPausedTickets),    
    fork(watchGetClosedTickets),
    fork(watchGetOpenTickets),
    fork(watchGetChannels),
    fork(watchAddContacts),
    fork(watchCreateChannel),
    fork(watchGetChatUserDetails),
    fork(watchGetChatUserConversations),
    fork(watchOnSendMessage),
    fork(watchReceiveMessage),
    fork(watchReadMessage),
    fork(watchReceiveMessageFromUser),
    fork(watchDeleteMessage),
    fork(watchForwardMessage),
    fork(watchDeleteUserMessages),
    fork(watchGetChannelDetails),
    fork(watchToggleFavouriteContact),
    fork(watchTriggerTicketOperation),
    fork(watchTriggerAddNote),    
    fork(watchTriggerTicketTask),    
    fork(watchGetArchiveContact),
    fork(watchToggleArchiveContact),
    fork(watchReadConversation),
    fork(watchDeleteImage),
    // fork(watchWebsockets),
  ]);
}

export default chatsSaga;
