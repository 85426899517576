// types
import { KnowbasesActionTypes, KnowbasesState } from "./types";

export const INIT_STATE: KnowbasesState = {
  knowbases: [],
  selectedKnowbase: null,
  currentKnowbase: {},  
  isKnowbaseEditMode: false,
  knowbaseDetails: {},
};

const Knowbases = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case KnowbasesActionTypes.CHANGE_SELECTED_KNOWBASE:
      return {
        ...state,
        selectedKnowbase: action.payload,
      };      
    case KnowbasesActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case KnowbasesActionTypes.GET_KNOWBASES:
          return {
            ...state,
            knowbases: action.payload.data,
            isKnowbasesFetched: true,
            getKnowbasesLoading: false,
          };
        case KnowbasesActionTypes.INVITE_KNOWBASE:
          return {
            ...state,
            isKnowbaseInvited: true,
          };
        case KnowbasesActionTypes.GET_KNOWBASE_DETAILS:
          return {
            ...state,
            knowbaseDetails: action.payload.data,
            isKnowbaseDetailsFetched: true,
            getKnowbaseDetailsLoading: false,
          };   
        case KnowbasesActionTypes.ON_SEND_KNOWBASE:
          return {
            ...state,
            isKnowbaseSent: true,
          };                 
        default:
          return { ...state };
      }

    case KnowbasesActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case KnowbasesActionTypes.GET_KNOWBASES:
          return {
            ...state,
            isKnowbasesFetched: false,
            getKnowbasesLoading: false,
          };
        case KnowbasesActionTypes.INVITE_KNOWBASE:
          return {
            ...state,
            isKnowbaseInvited: false,
          };
        case KnowbasesActionTypes.GET_KNOWBASE_DETAILS:
          return {
            ...state,
            isKnowbaseDetailsFetched: false,
            getKnowbaseDetailsLoading: false,
          }; 
        case KnowbasesActionTypes.ON_SEND_KNOWBASE:
          return {
            ...state,
            isKnowbaseSent: false,
          }; 
        default:
          return { ...state };
      }
    case KnowbasesActionTypes.GET_KNOWBASE_DETAILS:
      return {
        ...state,
        isKnowbaseDetailsFetched: false,
        getKnowbaseDetailsLoading: true,
      }; 
    case KnowbasesActionTypes.GET_KNOWBASES: {
      return {
        ...state,
        getKnowbasesLoading: true,
        isKnowbasesFetched: false,
      };
    }

    case KnowbasesActionTypes.INVITE_KNOWBASE:
      return {
        ...state,
        isKnowbaseInvited: false,
      };
    case KnowbasesActionTypes.RESET_KNOWBASES:
      const flag = action.payload.flag;
      const value = action.payload.value;
      let flags: any = {};
      flags[flag] = value;
      return {
        ...state,
        ...flags,
      };
    case KnowbasesActionTypes.ON_SEND_KNOWBASE:
      return {
        ...state,
        isKnowbaseSent: false,
      };    
    case KnowbasesActionTypes.TOGGLE_KNOWBASE_EDIT_MODE:
      return {
        ...state,
        isKnowbaseEditMode: action.payload,
      };         
    default:
      return { ...state };
  }
};

export default Knowbases;
