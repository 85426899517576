export enum ReportsActionTypes {
  API_RESPONSE_SUCCESS = "@@reports/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@reports/API_RESPONSE_ERROR",

  GET_REPORTS = "@@reports/GET_REPORTS",
  RESET_REPORTS = "@@reports/RESET_REPORTS",
  INVITE_REPORT = "@@reports/INVITE_REPORT",

  CHANGE_SELECTED_REPORT = "@@reports/CHANGE_SELECTED_REPORT", 

  GET_REPORT_DETAILS = "@@reports/GET_REPORT_DETAILS",   
}


export interface ReportsState {
  reports: Array<any>;
  selectedReport: string | number | null;
  reportDetails: {};
}
