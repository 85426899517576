import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { KnowbasesActionTypes } from "./types";
import {
  knowbasesApiResponseSuccess,
  knowbasesApiResponseError,
} from "./actions";

// api
import {
  getKnowbases as getKnowbasesApi,
  inviteKnowbase as inviteKnowbaseApi,
  getKnowbaseDetails as getKnowbaseDetailsApi,
  sendKnowbase as sendKnowbaseApi,
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getKnowbases({ payload: filters }: any) {
  try {
    const response: Promise<any> = yield call(getKnowbasesApi, filters);
    yield put(
      knowbasesApiResponseSuccess(KnowbasesActionTypes.GET_KNOWBASES, response)
    );
  } catch (error: any) {
    yield put(
      knowbasesApiResponseError(KnowbasesActionTypes.GET_KNOWBASES, error)
    );
  }
}

function* inviteKnowbase({ payload: newPassword }: any) {
  try {
    const response: Promise<any> = yield call(inviteKnowbaseApi, newPassword);
    yield put(
      knowbasesApiResponseSuccess(KnowbasesActionTypes.INVITE_KNOWBASE, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      knowbasesApiResponseError(KnowbasesActionTypes.INVITE_KNOWBASE, error)
    );
  }
}

function* getKnowbaseDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getKnowbaseDetailsApi, id);
    yield put(
      knowbasesApiResponseSuccess(KnowbasesActionTypes.GET_KNOWBASE_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      knowbasesApiResponseError(KnowbasesActionTypes.GET_KNOWBASE_DETAILS, error)
    );
  }
}

function* onSendKnowbase({ payload: { id, data } }: any) {
  try {
    const response: Promise<any> = yield call(sendKnowbaseApi, id, data);
    yield put(
      knowbasesApiResponseSuccess(KnowbasesActionTypes.ON_SEND_KNOWBASE, response)
    );
  } catch (error: any) {
    yield put(knowbasesApiResponseError(KnowbasesActionTypes.ON_SEND_KNOWBASE, error));
  }
}

export function* watchOnSendKnowbase() {
  yield takeEvery(KnowbasesActionTypes.ON_SEND_KNOWBASE, onSendKnowbase);
}

export function* watchGetKnowbaseDetails() {
  yield takeEvery(KnowbasesActionTypes.GET_KNOWBASE_DETAILS, getKnowbaseDetails);
}

export function* watchGetKnowbases() {
  yield takeEvery(KnowbasesActionTypes.GET_KNOWBASES, getKnowbases);
}

export function* watchInviteKnowbase() {
  yield takeEvery(KnowbasesActionTypes.INVITE_KNOWBASE, inviteKnowbase);
}

function* knowbasesSaga() {
  yield all([
    fork(watchGetKnowbases), 
    fork(watchInviteKnowbase),
    fork(watchGetKnowbaseDetails),
    fork(watchOnSendKnowbase),
    ]);
}

export default knowbasesSaga;
