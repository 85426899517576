
import React, { useState, useEffect } from "react";
import classnames from "classnames";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  toggleUserDetailsTab,
  toggleFavouriteContact,
  triggerTicketOperation,
  triggerTicketTask,
  getOpenTickets,
  getTickets,
  getClosedTickets,
  getChatUserDetails,
  toggleArchiveContact,
  getChatUserConversations,
} from "../../../redux/actions";

// components
import AudioCallModal from "../../../components/AudioCallModal";
import VideoCallModal from "../../../components/VideoCallModal";
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import ProfileUser from "./ProfileUser";
import Actions from "./Actions";
import Tasks from "./Tasks";


import BasicDetails from "./BasicDetails";
import Groups from "./Groups";
import Media from "../../../components/Media";
import AttachedFiles from "../../../components/AttachedFiles";
import Status from "./Status";
import Members from "./Members";
import Timers from "./Timers";


interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    chatUserDetails,
    getUserDetailsLoading,
    isOpenUserDetails,
    isFavouriteContactToggled,
    isTicketOperationTriggered,
    selectedChat,
  } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
    getUserDetailsLoading: state.Chats.getUserDetailsLoading,
    isOpenUserDetails: state.Chats.isOpenUserDetails,
    isFavouriteContactToggled: state.Chats.isFavouriteContactToggled,
    isTicketOperationTriggered:state.Chats.isTicketOperationTriggered,
    selectedChat: state.Chats.selectedChat,
  }));

  useEffect(() => {
    if (isFavouriteContactToggled) {
      dispatch(getChatUserDetails(chatUserDetails.id));
    }

    if (isTicketOperationTriggered) {

      dispatch(getChatUserDetails(chatUserDetails.id));
      dispatch(getChatUserConversations(selectedChat));
      dispatch(getTickets());
      // dispatch(getClosedTickets());
    }    
  }, [dispatch, isFavouriteContactToggled, isTicketOperationTriggered,chatUserDetails.id]);

  /*
  close tab
  */
  const onCloseUserDetails = () => {
    dispatch(toggleUserDetailsTab(false));
  };

  /*
    video call modal
    */
  const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
  const onOpenVideo = () => {
    setIsOpenVideoModal(true);
  };
  const onCloseVideo = () => {
    setIsOpenVideoModal(false);
  };

  /*
        audio call modal
    */
  const [isOpenAudioModal, setIsOpenAudioModal] = useState<boolean>(false);
  const onOpenAudio = () => {
    setIsOpenAudioModal(true);
  };
  const onCloseAudio = () => {
    setIsOpenAudioModal(false);
  };

  /*
  favourite
  */
  const onToggleFavourite = () => {
    dispatch(toggleFavouriteContact(chatUserDetails.id));
  };

  /*
  favourite
  */
  const onTriggerOperation = (id: number) => {
    // console.log('==id==', id)
    let params: any = {
      // text: data.text && data.text,
      // time: new Date().toISOString(),
      // image: data.image && data.image,
      ticketid: chatUserDetails.id,
      // actionid: id,
      triggerid: id,
      // attachments: data.attachments && data.attachments,
      // meta: {
      //   receiver: chatUserDetails.id,
      //   sender: userProfile.uid,
      // },
    };
 

    dispatch(triggerTicketOperation(params));
  };

  /*
  tasks
  */
  const onTriggerTask = (id: number) => {
    // console.log('==id==', id)
    let params: any = {
      // text: data.text && data.text,
      // time: new Date().toISOString(),
      // image: data.image && data.image,
      ticketid: chatUserDetails.id,
      // actionid: id,
      triggerid: id,
      // attachments: data.attachments && data.attachments,
      // meta: {
      //   receiver: chatUserDetails.id,
      //   sender: userProfile.uid,
      // },
    };
 

    dispatch(triggerTicketTask(params));
  };



  /*
  archive
  */
  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };

  return (
    <>
      <div
        className={classnames("user-profile-sidebar", {
          "d-block": isOpenUserDetails,
        })}
      >
        <div className="position-relative">
          {getUserDetailsLoading && <Loader />}

          <ProfileUser
            onCloseUserDetails={onCloseUserDetails}
            chatUserDetails={chatUserDetails}
            onOpenVideo={onOpenVideo}
            onOpenAudio={onOpenAudio}
          />

          {/* <!-- End profile user --> */}

{/*            <Actions
              chatUserDetails={chatUserDetails}
              onOpenVideo={onOpenVideo}
              onOpenAudio={onOpenAudio}
              onToggleFavourite={onToggleFavourite}
              onToggleArchive={onToggleArchive}
            />*/}

          {/* <!-- Start user-profile-desc --> */}
          <AppSimpleBar className="p-4 user-profile-desc">
            {" "}
            {/* simplebar */}
            <Actions
              chatUserDetails={chatUserDetails}
              onOpenVideo={onOpenVideo}
              onOpenAudio={onOpenAudio}
              onToggleFavourite={onToggleFavourite}
              onToggleArchive={onToggleArchive}
              onTriggerOperation={onTriggerOperation}
            />
            <Timers
              chatUserDetails={chatUserDetails}
              onOpenVideo={onOpenVideo}
              onOpenAudio={onOpenAudio}
              onToggleFavourite={onToggleFavourite}
              onToggleArchive={onToggleArchive}
              onTriggerOperation={onTriggerOperation}
            />            
            <Status 
              about={chatUserDetails.about} 
              stage={chatUserDetails.stage} 
              queue={chatUserDetails.queue} 
            />
{/*            {!isChannel ? (
              <>
                <BasicDetails chatUserDetails={chatUserDetails} />
                <hr className="my-4" />
                <Groups chatUserDetails={chatUserDetails} />
                <hr className="my-4" />
              </>
            ) : (
              <>
                <Members chatUserDetails={chatUserDetails} />
                <hr className="my-4" />
              </>
            )}
*/}

{/*
              <>
                <Members chatUserDetails={chatUserDetails} />
                <hr className="my-4" />
              </>

*/}




            <BasicDetails chatUserDetails={chatUserDetails} />
            <hr className="my-4" />

{/*
            <Tasks
              chatUserDetails={chatUserDetails}
              onOpenVideo={onOpenVideo}
              onOpenAudio={onOpenAudio}
              onToggleFavourite={onToggleFavourite}
              onToggleArchive={onToggleArchive}
              onTriggerTask={onTriggerTask}
            />
            

*/}

            <Media media={chatUserDetails.media} limit={3} />
            <hr className="my-4" />
            
            <AttachedFiles attachedFiles={chatUserDetails.attachedFiles} />
          </AppSimpleBar>
          {/* <!-- end user-profile-desc --> */}
          {isOpenAudioModal && (
            <AudioCallModal
              isOpen={isOpenAudioModal}
              onClose={onCloseAudio}
              user={chatUserDetails}
            />
          )}
          {isOpenVideoModal && (
            <VideoCallModal
              isOpen={isOpenVideoModal}
              onClose={onCloseVideo}
              user={chatUserDetails}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
