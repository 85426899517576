// import React from "react";

// const AvatarImage = ({ onClick }: AvatarImageProps) => {
//   return (
//     <img>

//     </img>
//   );
// };

// export default AvatarImage;



import React from "react";
import wasvg from "../assets/images/wa.svg";
import tgsvg from "../assets/images/tg.svg";
import ezsvg from "../assets/images/ez.svg";
import elcsvg from "../assets/images/ecl.svg";
import svglogo from "../assets/images/logo.svg";

interface AvatarImageProps {
  channel: string;
  // onClick?: () => void;
}

function getChannelSVG(channel: string): string {
  switch (channel) {
    case 'wa':
      return wasvg;
    case 'tg':
      return tgsvg;
    case 'ez':
      return ezsvg;
    case 'elc':
      return elcsvg;
    default:
      return svglogo;
  }
}

// const AvatarImage: React.FC<AvatarImageProps> = ({ channel, onClick }) => {
const AvatarImage = ({ channel }: AvatarImageProps) => {
  const src = getChannelSVG(channel);

  return (
    <img src={src} alt={`${channel} avatar`}  />
  );
};

export default AvatarImage;
