import { eventChannel, EventChannel } from 'redux-saga';
// import { take, put, call, fork, cancel } from 'redux-saga/effects';
import { takeEvery, fork, put, all, call, take  } from "redux-saga/effects";


import { getLoggedinUser } from "../../api/apiCore";

import config from "../../config";


import {
  showSuccessNotification,
  showErrorNotification,
  showActionNotification, 
  showMessageNotification, 
} from "../../helpers/notifications";

// import { eventChannel} from 'redux-saga';
// import ActionPattern from "redux-saga"
// import { ActionPattern, Buffer, Channel, GuardPredicate, Pattern, Task, Effect } from '@redux-saga/types'
import { ActionPattern } from '@redux-saga/types'
import { Action } from 'redux'

//actions
import {
  // getDirectMessages as getDirectMessagesAction,
  // getPausedTickets as getPausedTicketsAction,  
  // getClosedTickets as getClosedTicketsAction,
  // getOpenTickets as getOpenTicketsAction,  
  // getFavourites as getFavouritesAction,
  getTickets as getTicketsAction,
  getReceiveUpdate as getReceiveUpdateAction,
} from "../actions";


//===================================================

function initWebsocket() {  
  return eventChannel(emitter => {   
    // let pingInterval = null; 
    console.log('==logined iser==', getLoggedinUser());
    const user = getLoggedinUser();
    if (!user) {
      return () => {};
    } 

//=============================================================
    const wsUrl = `${config.WS_URL}?token=${user.access}`;
    // let ws;
    let ws: WebSocket ;
    let pingInterval: NodeJS.Timeout ;    
    // let pingInterval = NodeJS.Timeout;

    const connect = () => {
      ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        console.log('WebSocket connected');
        ws.send(JSON.stringify({ type: 'authenticate', token: user.access }));

        const sendPing = () => {
          if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ cmd: 'ping' }));
          }
        };

        pingInterval = setInterval(sendPing, 60 * 1000); // Ping every minute
      };

      ws.onerror = (error) => {
        console.log('WebSocket error:', error);
      };

      ws.onmessage = (e) => {
        console.log('Message came back', e);
        let msg = null;
        try {
          msg = JSON.parse(e.data);
          console.log('==msg==', msg);
        } catch(e: any) {
          console.error(`Error parsing : ${e.data}`);
        }
        if (msg) {
          // console.log('===emitting action===');
          // return emitter(getOpenTicketsAction());
          // return emitter(getReceiveUpdateAction());
          // const { payload: book } = msg        
          const ws_event = msg.event
          switch (ws_event) {
            case 'pong':
              console.log('..pong..');
              break;
              // return emitter({ type: ADD_BOOK, book })
            // case 'REMOVE_BOOK':
              // return emitter({ type: REMOVE_BOOK, book })
            default:
              showMessageNotification('New Message');
              emitter(getReceiveUpdateAction());
              // dispatch(getChatUserConversations(selectedChat));
              // emitter(getTicketsAction());
              break;
              // nothing to do
          }
        }
      }   

      ws.onclose = () => {
        console.log('WebSocket disconnected');
        clearInterval(pingInterval);
        retryConnection();
      };
    };    

    let retries = 0;
    const maxRetries = 5000;
    const retryDelay = 10*1000; // 5 seconds

    const retryConnection = () => {
      if (retries < maxRetries) {
        retries++;
        // console.log(`Reconnecting attempt ${retries}/${maxRetries} in ${retryDelay / 1000} seconds`);
        console.log(`Reconnecting attempt ${retries} in ${retryDelay / 1000} seconds`);        
        setTimeout(connect, retryDelay);
      } else {
        console.log('Max reconnection attempts reached. No more retries.');
      }
    };

    const handleOffline = () => {
      console.log(`----- offline -----`);
      // emitter(setInternetStatus(false));
      // if (ws) {
      //   ws.close();
      // }
    };

    const handleOnline = () => {
      console.log(`----- online -----`);
      // emitter(setInternetStatus(true));
      // retries = 0; // Reset retries on reconnect
      // connect();   // Reconnect WebSocket
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    connect();

    return () => {
      clearInterval(pingInterval);
      // if (ws) ws.close();
      if (ws) ws.close();
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);      
    };    
//==============================================================


  })
}

export function* watchWebsockets() {
  const channel: ActionPattern<Action<any>>  = yield call(initWebsocket);
  while (true) {
    const action: Action<any>  = yield take(channel);
    yield put(action);
  }
}

//=====================================================


function* websocketsSaga() {
  yield all([
    fork(watchWebsockets),
  ]);
}

export default websocketsSaga;