import { TicketsActionTypes } from "./types";

// common success
export const ticketsApiResponseSuccess = (actionType: string, data: any) => ({
  type: TicketsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const ticketsApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: TicketsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTickets = (filters?: object) => ({
  type: TicketsActionTypes.GET_TICKETS,
  payload: filters,
});

export const inviteTicket = (data: any) => ({
  type: TicketsActionTypes.INVITE_TICKET,
  payload: data,
});

export const resetTickets = (flag: string, value: any) => ({
  type: TicketsActionTypes.RESET_TICKETS,
  payload: { flag, value },
});
