import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import '../assets/css/audioplayer.css'; // Custom CSS for the player
// import styles from '../assets/css/timer.module.css';

interface AudioPlayerProps {
  url: string;
}

const AudioPlayer = ({ url }: AudioPlayerProps) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleProgress = (state: any) => {
    setProgress(state.played);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const handleEnded = () => {
    setIsPlaying(false); // Reset to play icon when audio ends
    setProgress(0);      // Optionally reset the progress
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="whatsapp-audio-player">
      <button onClick={handlePlayPause} className="aplay-button">
        {isPlaying ? (
          <i className="bx bx-pause"></i> // Boxicons Pause Icon
        ) : (
          <i className="bx bx-play"></i> // Boxicons Play Icon
        )}
      </button>

      <div className="aprogress-container">
        <div className="aprogress-bar">
          <div
            className="aprogress-filled"
            style={{ width: `${progress * 100}%` }}
          ></div>
        </div>
      </div>

      <span className="atime">
        {formatTime(progress * duration)} / {formatTime(duration)}
      </span>

      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={isPlaying}
        height="0px" // Hide the player
        width="0px"
        onProgress={handleProgress}
        onDuration={handleDuration}
        onEnded={handleEnded} // Add the onEnded event listener
      />
    </div>
  );
};

export default AudioPlayer;