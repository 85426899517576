export enum KnowbasesActionTypes {
  API_RESPONSE_SUCCESS = "@@knowbases/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@knowbases/API_RESPONSE_ERROR",

  GET_KNOWBASES = "@@knowbases/GET_KNOWBASES",
  RESET_KNOWBASES = "@@knowbases/RESET_KNOWBASES",
  INVITE_KNOWBASE = "@@knowbases/INVITE_KNOWBASE",
  ON_SEND_KNOWBASE = "@@knowbases/ON_SEND_KNOWBASE",

  CHANGE_SELECTED_KNOWBASE = "@@knowbases/CHANGE_SELECTED_KNOWBASE", 

  GET_KNOWBASE_DETAILS = "@@knowbases/GET_KNOWBASE_DETAILS",  
  TOGGLE_KNOWBASE_EDIT_MODE = "@@knowbases/TOGGLE_KNOWBASE_EDIT_MODE",
 
}
export interface KnowbasesState {
  knowbases: Array<any>;
  selectedKnowbase: string | number | null;
  currentKnowbase: {};  
  isKnowbaseEditMode: boolean;
  knowbaseDetails: {};
}
