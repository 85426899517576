//auth
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/token/";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const JWT_REGISTER = "/post-jwt-register";
export const POST_FAKE_REGISTER = "/post-fake-register";

export const USER_CHANGE_PASSWORD = "/user-change-password/";

// profile & settings
export const GET_PROFILE_DETAILS = "/profile-details/";
export const GET_USER_SETTINGS = "/user-settings/";
export const UPDATE_SETTINGS = "/update-user-settings";

// contacts
export const GET_CONTACTS = "/user-contacts";
export const INVITE_CONTACT = "/invite-contact";

// tickets
export const GET_TICKETS = "/get-tickets/";
export const INVITE_TICKET = "/invite-ticket";

// staffs
export const GET_STAFFS = "/user-staffs";
export const INVITE_STAFF = "/invite-staff";


// knowbase
export const GET_KNOWBASES = "/get_knowbaselist/";
export const INVITE_KNOWBASE = "/invite-knowbases";
export const GET_KNOWBASE_DETAILS = "/get-knowbase-details";
export const SEND_KNOWBASE = "/send-knowbase/";
// reports
export const GET_REPORTS = "/get-reportlist/";
export const INVITE_REPORT = "/invite-report";
export const GET_REPORT_DETAILS = "/get-report-details";

// calls
export const GET_CALLS_LIST = "/calls-list";

// bookmarks
export const GET_BOOKMARKS_LIST = "/bookmarks-list";
export const DELETE_BOOKMARK = "/bookmarks-delete";
export const UPDATE_BOOKMARK = "/bookmarks-update";

//cases
export const GET_CASE_USER_DETAILS = "/get-case-details";
export const GET_CASE_USER_CONVERSATIONS = "/get-case-conversations";
export const GET_OPEN_CASES = "/get-open-cases";

// chats
export const GET_FAVOURITES = "/get-favourites";
export const GET_DIRECT_MESSAGES = "/get-direct-messages";
export const GET_CHANNELS = "/get-channles";
export const ADD_CONTACTS = "/add-ticket/";
export const CREATE_CHANNEL = "/create-channel";
export const GET_CHAT_USER_DETAILS = "/get-user-details";
export const GET_CHAT_USER_CONVERSATIONS = "/get-user-conversations";
export const SEND_MESSAGE = "/send-message/";
export const RECEIVE_MESSAGE = "/receive-message";
export const READ_MESSAGE = "/read-message";
export const RECEIVE_MESSAGE_FROM_USER = "/receive-message-from-user";
export const DELETE_MESSAGE = "/delete-message";
export const FORWARD_MESSAGE = "/forward-message";
export const DELETE_USER_MESSAGES = "/delete-user-messages";
export const TOGGLE_FAVOURITE_CONTACT = "/toggle-favourite-contact";
export const TRIGGER_TICKET_OPERATION = "/ticket_operation/";
export const TRIGGER_ADD_NOTE = "/trigger_addnote/";
export const TRIGGER_TICKET_TASK = "/ticket_task/";
export const GET_ARCHIVE_CONTACT = "/get-archive-contacts";
export const TOGGLE_ARCHIVE_CONTACT = "/toggle-archive-contact";
export const READ_CONVERSATION = "/read-conversation";
export const DELETE_IMAGE = "/user-delete-img";

// groups
export const GET_CHANNEL_DETAILS = "/get-channel-details";

//ticket
export const GET_OPEN_TICKETS = "/get-open-tickets/";
export const GET_CLOSED_TICKETS = "/get-closed-tickets/";
export const GET_PAUSED_TICKETS = "/get-paused-tickets/";
export const GET_TICKET_USER_DETAILS = "/get-user-details/";
export const GET_TICKET_USER_CONVERSATIONS = "/get-user-conversations/";