import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getReports = (filters?: object) => {
  return api.get(url.GET_REPORTS, filters);
};

const inviteReport = (data: object) => {
  return api.create(url.INVITE_REPORT, data);
};

const getReportDetails = (id: string | number) => {
  return api.get(url.GET_REPORT_DETAILS + "/" + id + "/", { params: { id } });
};

export { getReports, inviteReport, getReportDetails };