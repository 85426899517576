const sortData = (field: string, data: Array<any>, dir?: string) => {
  let sortedRecord = [...data];
  if (dir && dir === "desc") {
    sortedRecord = sortedRecord.sort((a: any, b: any) => {
      if (typeof b[field] === "number") {
        return b[field] - a[field];
      } else {
        return (b[field] || "").toLowerCase() > (a[field] || "").toLowerCase()
          ? 1
          : -1;
      }
    });
  } else {
    sortedRecord = sortedRecord.sort((a: any, b: any) => {
      if (typeof b[field] === "number") {
        return a[field] - b[field];
      } else {
        return (a[field] || "").toLowerCase() > (b[field] || "").toLowerCase()
          ? 1
          : -1;
      }
    });
  }

  return sortedRecord;
};

const groupByFields = (array: any, f: any) => {
  /*
    params description :
        f : function which returnf the array of fields 
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }

        array : array of data to group e.g. : [{...}, {...}]       
    */

  var groups: any = {};
  array.forEach((o: any) => {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(group => {
    return groups[group];
  });
};
export interface DivideByKeyResultTypes {
  letter: string;
  data: Array<any>;
}
const divideByKey = (field: string, array: any) => {
  let map = array.reduce((p: any, c: any) => {
    let char = c[field].charAt(0).toUpperCase();
    p[char] = [].concat(p[char] || [], c);
    return p;
  }, {});

  let result = Object.keys(map).map(k => ({
    letter: k,
    data: map[k],
  }));
  result = sortData("letter", result);
  return result;
};


const divideByKeyField = (field: string, array: any) => {
  let map = array.reduce((p: any, c: any) => {
    let char = c[field];
    p[char] = [].concat(p[char] || [], c);
    return p;
  }, {});

  let result = Object.keys(map).map(k => ({
    letter: k,
    data: map[k],
  }));
  result = sortData("letter", result);
  return result;
};


const divideByKeyFieldWithSort = (field: string, sortfield: string, array: any) => {
  let map = array.reduce((p: any, c: any) => {
    let char = c[field];
    p[char] = [].concat(p[char] || [], c);
    return p;
  }, {});

  let result = Object.keys(map).map(k => ({
    letter: k,
    sortfield: map[k][0][sortfield],
    data: map[k],
  }));
  result = sortData("sortfield", result);
  return result;
};


// const randomColors = (array: Array<any>) => {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];  // Swap elements
//   }
//   return array;
// }

export { sortData, groupByFields, divideByKey, divideByKeyField, divideByKeyFieldWithSort };
