import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { ReportsActionTypes } from "./types";
import {
  reportsApiResponseSuccess,
  reportsApiResponseError,
} from "./actions";

// api
import {
  getReports as getReportsApi,
  inviteReport as inviteReportApi,
  getReportDetails as getReportDetailsApi,  
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getReports({ payload: filters }: any) {
  try {
    const response: Promise<any> = yield call(getReportsApi, filters);
    yield put(
      reportsApiResponseSuccess(ReportsActionTypes.GET_REPORTS, response)
    );
  } catch (error: any) {
    yield put(
      reportsApiResponseError(ReportsActionTypes.GET_REPORTS, error)
    );
  }
}

function* inviteReport({ payload: newPassword }: any) {
  try {
    const response: Promise<any> = yield call(inviteReportApi, newPassword);
    yield put(
      reportsApiResponseSuccess(ReportsActionTypes.INVITE_REPORT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      reportsApiResponseError(ReportsActionTypes.INVITE_REPORT, error)
    );
  }
}

function* getReportDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getReportDetailsApi, id);
    yield put(
      reportsApiResponseSuccess(ReportsActionTypes.GET_REPORT_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      reportsApiResponseError(ReportsActionTypes.GET_REPORT_DETAILS, error)
    );
  }
}

export function* watchGetReportDetails() {
  yield takeEvery(ReportsActionTypes.GET_REPORT_DETAILS, getReportDetails);
}

export function* watchGetReports() {
  yield takeEvery(ReportsActionTypes.GET_REPORTS, getReports);
}

export function* watchInviteReport() {
  yield takeEvery(ReportsActionTypes.INVITE_REPORT, inviteReport);
}

function* reportsSaga() {
  yield all([
    fork(watchGetReports), 
    fork(watchInviteReport),
    fork(watchGetReportDetails),
  ]);
}

export default reportsSaga;
