import React, { useEffect, useRef, useCallback, useState } from "react";
import GridLayout from 'react-grid-layout';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import AppSimpleBar from "../../../components/AppSimpleBar";

import LineChartItem from "./LineChart";
import BarChartItem from "./BarChart";
import PieChartItem from "./PieChart";
import StatCard from "./StatCard";

// Sample data for Recharts
const data = [
  { name: 'Pg A', uv: 400, pv: 2400, amt: 2400 },
  { name: 'Pg B', uv: 300, pv: 1398, amt: 2210 },
  { name: 'Pg C', uv: 200, pv: 9800, amt: 2290 },
  { name: 'Pg D', uv: 278, pv: 3908, amt: 2000 },
  { name: 'Pg E', uv: 189, pv: 4800, amt: 2181 },
  { name: 'Pg F', uv: 239, pv: 3800, amt: 2500 },
  { name: 'Pg G', uv: 349, pv: 4300, amt: 2100 },
];

// Stats data
const stats = [
  { title: 'People Served', value: 1520 },
  { title: 'Issues Resolved', value: 305 },
  { title: 'Avg. Response Time', value: '5 mins' },
];


// const charts = [
//   { title: 'People Served', value: 1520 },
//   { title: 'Issues Resolved', value: 305 },
//   { title: 'Avg. Response Time', value: '5 mins' },
//   { title: 'People Served', value: 1520 },
//   { title: 'Issues Resolved', value: 305 },
//   { title: 'Avg. Response Time', value: '5 mins' },  
// ];
const charts = [
  { title: 'Line In array 1', datakey: 'uv' },
  { title: 'Line in Array 2', datakey: 'pv' },
  { title: 'Line In array 3', datakey: 'uv' },
  { title: 'Line in Array 4', datakey: 'pv' },  
];

interface StatTypes {
  label: string;
  value: number | string; // Assuming value can be either a number or a string
}

interface ChartTypes {
  title: string;
  xlabel: string;
  ylabel: string;
  chart_type: string;
  data: any[];
}

interface ReportDetailTypes {
	id: number;
	name: string;
	category: string;
	firstName: string;
	charts: ChartTypes[]; 
  stats: StatTypes[];
}

interface ReportsProps { 
	reportDetails: ReportDetailTypes;
}
const Reports = ({ reportDetails }: ReportsProps) => {
  const layout = [
    { i: 'stat1', x: 0, y: 3, w: 3, h: 1 },
    { i: 'stat2', x: 3, y: 3, w: 3, h: 1 },
    { i: 'stat3', x: 6, y: 3, w: 3, h: 1 },
    { i: 'chart1', x: 0, y: 4, w: 4.5, h: 3 },
    { i: 'chart2', x: 4.5, y: 4, w: 4.5, h: 3 },
    { i: 'chart3', x: 0, y: 8, w: 4.5, h: 3 },
    { i: 'chart4', x: 4.5, y: 8, w: 4.5, h: 3 },
    { i: 'chart5', x: 0, y: 12, w: 4.5, h: 3 },  
    { i: 'chart6', x: 4.5, y: 12, w: 4.5, h: 3 },   
    { i: 'chart7', x: 0, y: 16, w: 4.5, h: 3 },   
    { i: 'chart8', x: 4.5, y: 16, w: 4.5, h: 3 },                 
  ];

	const ref = useRef<any>();
	const scrollElement = useCallback(() => {
		if (ref && ref.current) {
			const listEle = document.getElementById("chat-conversation-list");
			let offsetHeight = 0;
			if (listEle) {
				offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
			}
			if (offsetHeight) {
				ref.current
				  .getScrollElement()
				  .scrollTo({ top: offsetHeight, behavior: "smooth" });
			}
		}
	}, [ref]);

  return (
    <AppSimpleBar
      scrollRef={ref}
      className="report-wrapper1 p-3 p-lg-4 positin-relative"
    >
    
	    <div className="report-list">
		    <div className="container py-4">
		      {/* React Grid Layout */}
		      <GridLayout
		        key={reportDetails.id}
		        className="layout"
		        layout={layout}
		        cols={12}
		        rowHeight={100}
		        width={1200}
		        // compactType={"vertical"}
            autoSize={true}

		      >

			      {/* Render each StatCard for the statistics */}
{/*
			      {stats.map((stat, index) => (
			          <div key={`stat${index + 1}`}>
			            <StatCard title={stat.title} value={stat.value} />
			          </div>
			      ))}

			      */}




			      {(reportDetails.stats || []).map((stat: StatTypes, index) => (
			          <div key={`stat${index + 1}`} className="bg-light p-3 rounded shadow-sm">
			            <StatCard title={stat.label} value={stat.value} />
			          </div>
			      ))}



{/*
        {stats.map((stat, index) => (
          <div key={`stat${index + 1}`} className="bg-light p-3 rounded shadow-sm">
            <h5 className="text-secondary">{stat.title}</h5>
            <h3>{stat.value}</h3>
          </div>
        ))}

*/}
		        {/* Charts */}

{/*		        
		        {charts.map((chart, index) => (
		          <div key={`chart${index + 1}`}>
		            <ChartItem title={chart.title} datakey={chart.datakey} data={data} />
		          </div>
		        ))}

*/}

		        {(reportDetails.charts || []).map((chart: ChartTypes, index) => (
		          <div key={`chart${index + 1}`}  className="bg-white p-3 rounded shadow-sm" >
		            {/*<ChartItem title={chart.title} datakey={`value`} data={chart.data} />*/}
		              {/*<BarChartItem title={chart.title} xlabel={chart.xlabel} ylabel={chart.ylabel} datakey={`value`} data={chart.data} />*/}
				        
				        { chart.chart_type === "P" ? ( 
		              <PieChartItem title={chart.title} xlabel={chart.xlabel} ylabel={chart.ylabel} datakey={`value`} data={chart.data} />
				        ) : chart.chart_type === "B" ? (
		              <BarChartItem title={chart.title} xlabel={chart.xlabel} ylabel={chart.ylabel} datakey={`value`} data={chart.data} />
				        ) : (
		              <LineChartItem title={chart.title} xlabel={chart.xlabel} ylabel={chart.ylabel} datakey={`value`} data={chart.data} />
				        )}		          
		          </div>
		        ))}



{/*
		        <div key="chart8" className="bg-white p-3 rounded shadow-sm">
		          <h6>Line Chart 2</h6>
		          <ResponsiveContainer width="100%" height={250}>
		            <LineChart data={data}>
		              <CartesianGrid stroke="#e0dfdf" />
		              <XAxis dataKey="name" />
		              <YAxis />
		              <Tooltip />
		              <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
		            </LineChart>
		          </ResponsiveContainer>
		        </div>


*/}
		      </GridLayout>
		    </div>
		</div>    


    </AppSimpleBar>
  	);
  
};

export default Reports;