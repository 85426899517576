// types
import { TicketsActionTypes, TicketsState } from "./types";

export const INIT_STATE: TicketsState = {
  tickets: [],
};

const Tickets = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case TicketsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case TicketsActionTypes.GET_TICKETS:
          return {
            ...state,
            tickets: action.payload.data,
            isTicketsFetched: true,
            getTicketsLoading: false,
          };
        case TicketsActionTypes.INVITE_TICKET:
          return {
            ...state,
            isTicketInvited: true,
          };
        default:
          return { ...state };
      }

    case TicketsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case TicketsActionTypes.GET_TICKETS:
          return {
            ...state,
            isTicketsFetched: false,
            getTicketsLoading: false,
          };
        case TicketsActionTypes.INVITE_TICKET:
          return {
            ...state,
            isTicketInvited: false,
          };
        default:
          return { ...state };
      }

    case TicketsActionTypes.GET_TICKETS: {
      return {
        ...state,
        getTicketsLoading: true,
        isTicketsFetched: false,
      };
    }

    case TicketsActionTypes.INVITE_TICKET:
      return {
        ...state,
        isTicketInvited: false,
      };
    case TicketsActionTypes.RESET_TICKETS:
      const flag = action.payload.flag;
      const value = action.payload.value;
      let flags: any = {};
      flags[flag] = value;
      return {
        ...state,
        ...flags,
      };
    default:
      return { ...state };
  }
};

export default Tickets;
