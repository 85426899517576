import React from 'react';
import { 
	Pie,
	PieChart,
	Cell, 
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer 
} from 'recharts';

interface PieChartItemProps {
  title: string;
  datakey: string;
  xlabel: string;
  ylabel: string;  
  // data: Array<{ name: string; uv: number; pv: number; amt: number }>;
  data: any[];
}

// const ChartItem: React.FC<ChartItemProps> = ({ title, datakey, data }) => {
const PieChartItem = ({ title, datakey, xlabel, ylabel, data }: PieChartItemProps) => {
  
  // const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];
const COLORS = [
  '#393b79', '#393b79', '#5254a3', '#6b6ecf', '#6b6ecf', '#9c9ede',
  '#637939', '#637939', '#8ca252', '#b5cf6b', '#b5cf6b', '#cedb9c',
  '#8c6d31', '#8c6d31', '#bd9e39', '#e7ba52', '#e7cb94', '#e7cb94',
  '#843c39', '#ad494a', '#ad494a', '#d6616b', '#e7969c', '#e7969c',
  '#7b4173', '#a55194', '#a55194', '#ce6dbd', '#de9ed6', '#de9ed6'
];

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];  // Swap elements
  }
  return array;
}
	// const data01 = [
	//   { label: 'Group A', value: 400 },
	//   { label: 'Group B', value: 300 },
	//   { label: 'Group C', value: 300 },
	//   { label: 'Group D', value: 200 },
	//   { label: 'Group E', value: 278 },
	//   { label: 'Group F', value: 189 },
	// ];
    shuffleArray(COLORS);

	interface CustomLabelProps {
	  label: string;
	  value: number;
	}

	const renderCustomLabel = ({ label, value }: CustomLabelProps) => `${label}: ${value}`;

	// const renderCustomLabel = ({ name, value }) => `${name}: ${value}`;
	// const renderCustomLabel = ({ name }) => name;
  return (
    <>
      <h6>{title}</h6>
      <ResponsiveContainer width="100%" height={250}>
		  <PieChart>
		    <Pie
		      data={data}
		      dataKey="value"
		      nameKey="label"
		      cx="50%"
		      cy="50%"
		      outerRadius={80}
		      fill="#8884d8"
		      label={renderCustomLabel}  // Custom label renderer
		    >
		      {data.map((entry, index) => (
		        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
		      ))}
		    </Pie>
		    <Tooltip />
		    <Legend />
		  </PieChart>
	
      </ResponsiveContainer>
    </>
  );
};

export default PieChartItem;
