export enum TicketsActionTypes {
  API_RESPONSE_SUCCESS = "@@tickets/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@tickets/API_RESPONSE_ERROR",

  GET_TICKETS = "@@tickets/GET_TICKETS",
  RESET_TICKETS = "@@tickets/RESET_TICKETS",
  INVITE_TICKET = "@@tickets/INVITE_TICKET",
}
export interface TicketsState {
  tickets: Array<any>;
}
