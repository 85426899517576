import React, { useEffect, useRef, useCallback, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for Quill
import AppSimpleBar from "../../../components/AppSimpleBar";
import RichTextEditor from "./RichTextEditor";
import { Alert, Form } from "reactstrap";
import MoreMenu from "./MoreMenu";

import EndButtons from "./EndButtons"
import StartButtons from "./StartButtons"


import Media from "../../../components/Media";
import AttachedFiles from "../../../components/AttachedFiles";
import { AttachedfileTypes, AttachedfileItemTypes, MediaTypes } from "../../../data/myProfile";

import { Delta, Sources } from 'quill';
import UnprivilegedEditor from "quill"
interface KnowbaseDetailTypes {
  id: number;
  name: string;
  text: string;
  attachments: string[];
  media: MediaTypes;
  attachedFiles: AttachedfileTypes;
  // firstName: string;
  // charts: ChartTypes[]; 
  // stats: StatTypes[];
}

interface KnowbasesProps { 
  knowbaseDetails: KnowbaseDetailTypes;
  onSend: (data: any) => void;
  isKnowbaseEditMode: boolean;
  formData: any;
  handleInputChange: (name: string, value: any) => void;
}


// Define the file type array in useState
const Content = ({ knowbaseDetails, onSend , isKnowbaseEditMode, formData, handleInputChange }: KnowbasesProps) => {
// const RichTextEditor = ({ }: RichTextEditorProps) => {  
  const [content, setContent] = useState<string>(''); // State to hold the rich text
  // const [attachments, setAttachments] = useState<FileList | null>(null); // State for file attachments
  const [isEditing, setIsEditing] = useState<boolean>(false); // Toggle between edit and view mode
  const [editorContent, setEditorContent] = useState('');
  const [attachments, setAttachments] = useState([]);
  // Handle file selection
  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files) {
  //     setAttachments(e.target.files); // Store selected files
  //   }
  // };

  // Submit form (rich text + attachments)
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('content', content); // Add the rich text content

    // Append each file (multiple files supported)
    // if (attachments) {
    //   for (let i = 0; i < attachments.length; i++) {
    //     formData.append('attachments', attachments[i]);
    //   }
    // }

    // Example submission (you would replace this with your API call)
    fetch('/api/submit', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Handle toggling between edit and view mode
  const toggleEditMode = () => {
    // setIsEditing(!isEditing);
  };





  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 0;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current
          .getScrollElement()
          .scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);



  const handleChange = useCallback(
    (newContent) => {
      setEditorContent(newContent);
    },
    []
  );





  /*
  more menu collapse
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  /*
  disable send button
  */
  // const [disabled, setDisabled] = useState<boolean>(true);

  /*
  input text
  */
  const [text, setText] = useState<string>(knowbaseDetails && knowbaseDetails.text );
  const onChangeText = (value: string) => {
    setText(value);
  };

  /*
  images
  */
  // const [images, setImages] = useState<Array<any> | null | undefined>();
  const onSelectImages = (images: Array<any>) => {
    // setImages(images);
    handleInputChange('images', images);
  };

  /*
  files
  */
  // const [files, setFiles] = useState<Array<any> | null | undefined>();
  const onSelectFiles = (files: Array<any>) => {
    // setFiles(files);
    handleInputChange('files', files);
  };
  // useEffect(() => {
  //   if (text || images || files) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // }, [text, images, files]);

  // const onSubmit = () => {
  //   let data: any = {};
  //   if (text) {
  //     data["text"] = text;
  //   }
  //   if (images && images.length) {
  //     const imgs = (images || []).map((i: any, key: number) => {
  //       const src = URL.createObjectURL(i);
  //       return {
  //         id: key + 1,
  //         downloadLink: i,
  //         src: src,
  //       };
  //     });
  //     data["image"] = imgs;
  //   }

  //   if (files && files.length) {
  //     const fs = (files || []).map((f: any, key: number) => {
  //       const src = URL.createObjectURL(f);
  //       return {
  //         id: key + 1,
  //         name: f.name,
  //         downloadLink: f,
  //         src: src,
  //         desc: f.size,
  //       };
  //     });
  //     data["attachments"] = fs;
  //   }

  //   // alert(data);
  //   // console.log('the data', data);

  //   // setText("");
  //   // setImages(null);
  //   // setFiles(null);
  //   onSend(data);
  // };

  const onClearMedia = () => {
    // setImages(null);
    handleInputChange('images', []);
    // setFiles(null);
    handleInputChange('files', []);
  };



  // useEffect(() => {
  //   if (knowbaseDetails && knowbaseDetails.text) {
  //     setText(knowbaseDetails.text);
  //   }
  // }, [knowbaseDetails]);

  // useEffect(() => {
  //     setContent(knowbaseDetails.text);
  // }, [knowbaseDetails]);

  const handleQuillChange = (content: string) => {
    handleInputChange('text', content);
  };

  return (

    <AppSimpleBar
      scrollRef={ref}
      className="report-wrapper1 p-3 p-lg-4 positin-relative"
    >
      <div className="report-list">
        <div className="container py-4">





            <div className="card shadow-sm">

{/*
              <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Rich Text Editor with Attachments</h4>
                <button className="btn btn-light" onClick={toggleEditMode}>
                  {isKnowbaseEditMode ? 'Switch to View Mode' : 'Switch to Edit Mode'}
                </button>
              </div>
 
*/}
              <div className="card-body">
                {isKnowbaseEditMode ? (
                  <Form
                  >

                    {/* Rich Text Editor */}
                    <div className="form-group mb-4">
                      {/*<ReactQuill value={knowbaseDetails.text} onChange={setContent} className="border rounded" />*/}
{/*
                      <ReactQuill 
                        value={ormData.text} 
                        onChange={setText}
                        className="border rounded" 
                        readOnly={true}
                      />

*/}
                      <ReactQuill 
                        value={formData.text} 
                        onChange={handleQuillChange}
                        className="border rounded" 
                      />
{/*
                      <ReactQuill 
                        value={formData.text} 
                        onChange={(e: any) => {
                          // onChange(e.target.value);
                          console.log(e.target);
                          // handleInputChange('sas', e.target.value);
                        }}


                        className="border rounded"
                      />
*/}
                      
                    </div>

                    {/* File Upload */}
                    <div className="form-group mb-4">
                      <label htmlFor="attachments" className="form-label">
                        Attach files:
                      </label>

{/*
                      <input
                        type="file"
                        className="form-control"
                        id="attachments"
                        multiple
                        onChange={handleFileChange}
                      />

*/}
                    </div>


                    <div className="col-auto">
                      <StartButtons
                        onToggle={onToggle}
                        text={text}
                        onChange={onChangeText}
                      />
                    </div>

                  </Form>

                ) : (
                  // View Mode (showing the rich text content)
                  <div className="border rounded p-3" dangerouslySetInnerHTML={{ __html: formData.text }} />
                )}


                <MoreMenu
                  isOpen={isOpen}
                  onSelectImages={onSelectImages}
                  onSelectFiles={onSelectFiles}
                  onToggle={onToggle}
                />


                {/* Display attached files */}
                {attachments.length > 0 && (
                  <div className="card-footer">
                    <h5>Attached Files:</h5>
                    <ul className="list-group">
                      {attachments.map((file, index) => (
                        <li key={index} className="list-group-item">
                          {file}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
          
                <Media media={knowbaseDetails?.media} limit={3} />
                <hr className="my-4" />
                {/*sdsdsd*/}
                <AttachedFiles attachedFiles={knowbaseDetails?.attachedFiles} />




              </div>
              
            </div> 
        </div>
      </div>  



    </AppSimpleBar>
  );
};

export default Content;
