import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getTickets = (filters?: object) => {
  return api.get(url.GET_TICKETS, filters);
};

const inviteTicket = (data: object) => {
  return api.create(url.INVITE_TICKET, data);
};
export { getTickets, inviteTicket };