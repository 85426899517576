import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import '../assets/css/videoplayer.css'; // Custom CSS for the player
// import styles from '../assets/css/timer.module.css';

interface VideoPlayerProps {
  url: string;
}

const VideoPlayer = ({ url }: VideoPlayerProps) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  // Toggle play/pause
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  // Handle video progress
  const handleProgress = (state: any) => {
    setProgress(state.played);
  };

  // Handle video duration
  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  // Reset when video ends
  const handleEnded = () => {
    setIsPlaying(false);
    setProgress(0);
  };

  // Format time for display (e.g., 1:30)
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="whatsapp-audio-player">
      {/* Video container with overlay controls */}
      <div className="video-container">
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={isPlaying}
          width="100%" // Make the video responsive
          height="auto"
          onProgress={handleProgress}
          onDuration={handleDuration}
          onEnded={handleEnded}
          className="video-player"
        />

        {/* Play/Pause button */}
        <button onClick={handlePlayPause} className="vplay-button">
          {isPlaying ? (
            <i className="bx bx-pause"></i> // Boxicons Pause Icon
          ) : (
            <i className="bx bx-play"></i> // Boxicons Play Icon
          )}
        </button>

        {/* Video progress bar */}
        <div className="vprogress-container">
          <div className="vprogress-bar">
            <div
              className="vprogress-filled"
              style={{ width: `${progress * 100}%` }}
            ></div>
          </div>
        </div>

        {/* Time display */}
        <span className="vtime">
          {formatTime(progress * duration)} / {formatTime(duration)}
        </span>
      </div>
    </div>
  );
};

export default VideoPlayer;