import React from 'react';

// Define props for StatCard
interface StatCardProps {
  title: string;
  value: string | number;
}

// Component for each stat card
// const StatCard: React.FC<StatCardProps> = ({ title, value }) => {
const StatCard = ({ title, value }: StatCardProps) => {  
  return (
    <>
      <h5 className="text-secondary">{title}</h5>
      <h3>{value}</h3>
    </>
  );
};

export default StatCard;
