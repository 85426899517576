import React from "react";
import classnames from "classnames";

// hooks
import { useRedux } from "../../hooks/index";

// hooks
import { useConversationUserType } from "../../hooks/index";

// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";
import ReportPage from "./Reports/Page"
import KnowbasePage from "./Knowbases/Page"
// import RightMenu from "./Leftbar";
// import SideMenu from "../../layouts/Default/SideMenu";
// import RightMenu from "../../layouts/Default/RightMenu";
interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const { useAppSelector } = useRedux();
  // const { activeTab } = useAppSelector(state => ({
  //   activeTab: state.Layout.activeTab,
  // }));
  const { selectedChat, selectedReport, selectedKnowbase, activeTab  } = useAppSelector(state => ({
    selectedChat: state.Chats.selectedChat,
    selectedReport: state.Reports.selectedReport,
    selectedKnowbase: state.Knowbases.selectedKnowbase,
    activeTab: state.Layout.activeTab,
  }));

  const { isChannel } = useConversationUserType();
  // console.log('=index=');

  return (
    <>
      <Leftbar />

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat || selectedReport || selectedKnowbase,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>

        { activeTab === "pills-reports" && selectedReport !== null  ? ( 
          <ReportPage  isChannel={isChannel} />
          ) : activeTab === "pills-knowbase" && selectedKnowbase !== null  ? (
          <KnowbasePage  isChannel={isChannel} />          
          ) : selectedChat !== null ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser isChannel={isChannel} />
            </div>
            <UserProfileDetails isChannel={isChannel} />
          </div>
          // <RightMenu />

        ) : (
          <Welcome />
        )}
      </div>
      
      {/*{selectedChat !== null ? ( <RightMenu /> ) : ( '' )}*/}

    </>
  );
};

export default Index;
