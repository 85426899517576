import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getKnowbases = (filters?: object) => {
  return api.get(url.GET_KNOWBASES, filters);
};

const inviteKnowbase = (data: object) => {
  return api.create(url.INVITE_KNOWBASE, data);
};

const getKnowbaseDetails = (id: string | number) => {
  return api.get(url.GET_KNOWBASE_DETAILS + "/" + id + "/", { params: { id } });
};

const sendKnowbase = (id: string | number, data: object) => {
  console.log("47 line", data);
  console.log("47 id", id);
  return api.createWithFile(url.SEND_KNOWBASE + id + "/", data);
};

export { getKnowbases, inviteKnowbase, getKnowbaseDetails, sendKnowbase };