import React, { useState, useRef, useEffect } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";

interface AudioRecorderProps {
  onSelectAudio: (audio: File) => void;
  onStopRecorder: () => void;
}

const AudioRecorder = ({ onSelectAudio, onStopRecorder }: AudioRecorderProps) => {
  const [isRecording, setIsRecording] = useState(true); // Toggle state for recording
  const startRecordingRef = useRef<() => void>(() => {}); // Create refs for start/stop
  const stopRecordingRef = useRef<() => void>(() => {});

  // Automatically start recording when component loads
  useEffect(() => {
    if (isRecording && startRecordingRef.current) {
      startRecordingRef.current(); // Trigger recording after component loads
    }
  }, [isRecording]);

  return (
    <>
      {/* ReactMediaRecorder for audio */}
      <ReactMediaRecorder
        audio
        onStop={(blobUrl, blob) => {
          // console.log('=====2 recorder 2======');
          onSelectAudio(new File([blob], 'audio_file.wav', { type: 'audio/wav' }));
          setIsRecording(false); // Reset recording state after stop
          
          // console.log('=====2 recorder 2======');
          onStopRecorder();
        }}
        render={({ status, startRecording, stopRecording, mediaBlobUrl }) => {
          // Assign start/stopRecording functions to refs
          startRecordingRef.current = startRecording;
          stopRecordingRef.current = stopRecording;

          return (
            <>
              <button
                type="button"
                color="none"
                className="btn btn-link text-decoration-none btn-lg waves-effect"
                id="audio-btn"
                onClick={() => {
                  if (isRecording) {
                    stopRecording();
                    setIsRecording(false);
                  } else {
                    startRecording();
                    setIsRecording(true);
                  }
                }}
              >
                {isRecording ? (
                  <i className="bx bx-stop align-middle"></i> // Boxicons Stop Icon
                ) : (
                  <i className="bx bx-microphone align-middle"></i> // Boxicons Microphone Icon
                )}
              </button>

              <UncontrolledPopover trigger="focus" placement="top" target="audio-btn" isOpen={isRecording}>
                <PopoverBody>
                  <div className="loader-line">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          );
        }}
      />
    </>
  );
};

export default AudioRecorder;
