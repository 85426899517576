import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { TicketsActionTypes } from "./types";
import {
  ticketsApiResponseSuccess,
  ticketsApiResponseError,
} from "./actions";

// api
import {
  getTickets as getTicketsApi,
  inviteTicket as inviteTicketApi,
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getTickets({ payload: filters }: any) {
  try {
    const response: Promise<any> = yield call(getTicketsApi, filters);
    yield put(
      ticketsApiResponseSuccess(TicketsActionTypes.GET_TICKETS, response)
    );
  } catch (error: any) {
    yield put(
      ticketsApiResponseError(TicketsActionTypes.GET_TICKETS, error)
    );
  }
}

function* inviteTicket({ payload: newPassword }: any) {
  try {
    const response: Promise<any> = yield call(inviteTicketApi, newPassword);
    yield put(
      ticketsApiResponseSuccess(TicketsActionTypes.INVITE_TICKET, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      ticketsApiResponseError(TicketsActionTypes.INVITE_TICKET, error)
    );
  }
}

export function* watchGetTickets() {
  yield takeEvery(TicketsActionTypes.GET_TICKETS, getTickets);
}

export function* watchInviteTicket() {
  yield takeEvery(TicketsActionTypes.INVITE_TICKET, inviteTicket);
}

function* ticketsSaga() {
  yield all([fork(watchGetTickets), fork(watchInviteTicket)]);
}

export default ticketsSaga;
