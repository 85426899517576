import { ReportsActionTypes } from "./types";

// common success
export const reportsApiResponseSuccess = (actionType: string, data: any) => ({
  type: ReportsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const reportsApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: ReportsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getReports = (filters?: object) => ({
  type: ReportsActionTypes.GET_REPORTS,
  payload: filters,
});

export const inviteReport = (data: any) => ({
  type: ReportsActionTypes.INVITE_REPORT,
  payload: data,
});

export const resetReports = (flag: string, value: any) => ({
  type: ReportsActionTypes.RESET_REPORTS,
  payload: { flag, value },
});


export const changeSelectedReport = (selectedReport: string | number | null) => ({
  type: ReportsActionTypes.CHANGE_SELECTED_REPORT,
  payload: selectedReport,
});

export const getReportDetails = (selectedReport: string | number | null) => ({
  type: ReportsActionTypes.GET_REPORT_DETAILS,
  payload: selectedReport,
});