import React from 'react';
import { 
	Bar,
	BarChart,
	LineChart, 
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer 
} from 'recharts';

interface BarChartItemProps {
  title: string;
  datakey: string;
  xlabel: string;
  ylabel: string;
  // data: Array<{ name: string; uv: number; pv: number; amt: number }>;
  data: any[];
}

// const ChartItem: React.FC<ChartItemProps> = ({ title, datakey, data }) => {
const BarChartItem = ({ title, datakey, xlabel, ylabel, data }: BarChartItemProps) => {
  return (
    <>
      <h6>{title}</h6>
      <ResponsiveContainer width="100%" height={250}>
		  <BarChart layout="horizontal" data={data}>
		    <CartesianGrid stroke="#e0dfdf" />
    <XAxis dataKey="label" label={{ value: xlabel, position: 'insideBottom', offset: -5 }} />
    <YAxis label={{ value: ylabel, angle: -90, position: 'insideLeft' }} />
		    <Tooltip />
		    <Legend />
		    <Bar dataKey="value" fill="#8884d8" />
		  </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default BarChartItem;
