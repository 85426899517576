// types
import { ReportsActionTypes, ReportsState } from "./types";

export const INIT_STATE: ReportsState = {
  reports: [],
  selectedReport: null,
  reportDetails: {},
};

const Reports = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ReportsActionTypes.CHANGE_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: action.payload,
      };    
    case ReportsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ReportsActionTypes.GET_REPORTS:
          return {
            ...state,
            reports: action.payload.data,
            isReportsFetched: true,
            getReportsLoading: false,
          };
        case ReportsActionTypes.INVITE_REPORT:
          return {
            ...state,
            isReportInvited: true,
          };
        case ReportsActionTypes.GET_REPORT_DETAILS:
          return {
            ...state,
            reportDetails: action.payload.data,
            isReportDetailsFetched: true,
            getReportDetailsLoading: false,
          };
        default:
          return { ...state };
      }

    case ReportsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ReportsActionTypes.GET_REPORTS:
          return {
            ...state,
            isReportsFetched: false,
            getReportsLoading: false,
          };
        case ReportsActionTypes.INVITE_REPORT:
          return {
            ...state,
            isReportInvited: false,
          };
        case ReportsActionTypes.GET_REPORT_DETAILS:
          return {
            ...state,
            isReportDetailsFetched: false,
            getReportDetailsLoading: false,
          };          
        default:
          return { ...state };
      }

    case ReportsActionTypes.GET_REPORTS: {
      return {
        ...state,
        getReportsLoading: true,
        isReportsFetched: false,
      };
    }
    case ReportsActionTypes.GET_REPORT_DETAILS:
      return {
        ...state,
        isReportDetailsFetched: false,
        getReportDetailsLoading: true,
      };
    case ReportsActionTypes.INVITE_REPORT:
      return {
        ...state,
        isReportInvited: false,
      };
    case ReportsActionTypes.RESET_REPORTS:
      const flag = action.payload.flag;
      const value = action.payload.value;
      let flags: any = {};
      flags[flag] = value;
      return {
        ...state,
        ...flags,
      };
    default:
      return { ...state };
  }
};

export default Reports;
