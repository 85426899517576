import { KnowbasesActionTypes } from "./types";

// common success
export const knowbasesApiResponseSuccess = (actionType: string, data: any) => ({
  type: KnowbasesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const knowbasesApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: KnowbasesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getKnowbases = (filters?: object) => ({
  type: KnowbasesActionTypes.GET_KNOWBASES,
  payload: filters,
});

export const inviteKnowbase = (data: any) => ({
  type: KnowbasesActionTypes.INVITE_KNOWBASE,
  payload: data,
});

export const resetKnowbases = (flag: string, value: any) => ({
  type: KnowbasesActionTypes.RESET_KNOWBASES,
  payload: { flag, value },
});

export const changeSelectedKnowbase = (selectedKnowbase: string | number | null) => ({
  type: KnowbasesActionTypes.CHANGE_SELECTED_KNOWBASE,
  payload: selectedKnowbase,
});

export const getKnowbaseDetails = (selectedKnowbase: string | number | null) => ({
  type: KnowbasesActionTypes.GET_KNOWBASE_DETAILS,
  payload: selectedKnowbase,
});

export const onSendKnowbase = (id: string | number, data: any) => ({
  type: KnowbasesActionTypes.ON_SEND_KNOWBASE,
  payload: { id, data },
});

export const toggleKnowbaseEditMode = (value: boolean) => ({
  type: KnowbasesActionTypes.TOGGLE_KNOWBASE_EDIT_MODE,
  payload: value,
});