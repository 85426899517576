import React from "react";

interface IndexProps {}
const Index = (props: IndexProps) => {

  return <div> this is the exmaple page</div>;
  
};

export default Index;
